<template>
  <section>
    <div class="col-12">
      <div class="row">
        <!-- ------------------------- -->
        <div class="col-8 text-white maincard">
          <div class="row p-3">
            <div class="col-12 p-0" @click="callemit()">
              <div class="row">
                <div class="col-9 m-0">
                  <div class="row">
                    <div class="col-12 py-2">
                      <div
                        type="button"
                        class="row pl-2"
                        @click="is_favourite()"
                      >
                        <i
                          class="far fa-star px-1"
                          v-if="item.is_favourite == 0"
                        ></i>
                        <i class="fas fa-star px-1" v-else></i>
                      </div>
                    </div>
                    <div class="col-12 pl-4 py-2">
                      <span style="font-size: 1.2rem">{{ item.status }} </span>
                    </div>
                  </div>
                </div>
                <div class="col-2 h-50 p-0 text-center viewcard">
                  <div type="button" class="col-12 p-0" @click="status_code()">
                    <i class="far fa-eye" v-if="item.status_code == 1"></i>
                    <i class="far fa-eye-slash" v-else></i>
                  </div>
                  <div class="col-12 p-0">{{ item.view }}</div>
                </div>
                <div class="col-1 p-0"></div>
              </div>
            </div>
            <div class="col-12 pl-2 py-2" @click="callemit()">
              <span style="font-size: 1.4rem">{{ item.alias }}</span>
            </div>
            <div class="col-12 pl-2 py-2">
              <div class="row">
                <div class="col-11">
                  <p
                    style="font-size: 0.8rem; overflow-wrap: break-word"
                    ref="mylink"
                  >
                    {{ item.short_url }}
                  </p>
                </div>
                <div
                  type="button"
                  class="col p-0 text-right"
                  @click="copyURL()"
                >
                  <i class="fas fa-clone"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------- -->
        <div class="col-4 p-0 m-0">
          <div class="col-12 py-3 text-white text-center sidecard">
            {{ item.expired_date }}
          </div>
          <div class="col-12">
            <div class="row d-flex align-items-center">
              <!-- --------------------------- qr--------------------- -->
              <div class="col m-0 p-0" @click="reloamodal()">
                <figure
                  id="my-node"
                  ref="printMe"
                  type="button"
                  class="mt-2 ml-2 qrcode myborder"
                >
                  <VueQrcode
                    :value="item.short_url"
                    tag="svg"
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 80,
                    }"
                  ></VueQrcode>
                  <img
                    v-if="havepic"
                    class="qrcode__image"
                    src="/images/logo_eco.png"
                  />
                </figure>
                <!-- <vue-qrcode value="Hello, World!" :options="{ width: 200 }"></vue-qrcode> -->
              </div>

              <!-- --------------------------- qr--------------------- -->
              <!-- ----------------- call modal qr ------------------- -->

              <div type="button" class="col" @click="qrsave()">
                <i class="fas fa-download"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-0 p-0">
        <ModalQr
          :key="item.id"
          :modalId="item.id"
          :mydatamodal="item.short_url"
        />
      </div>
      <!-- ----------------- call modal qr ------------------- -->
    </div>
  </section>
</template>
<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import ModalQr from "@/components/shorturl/ModalQr.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "ShortUrlCard",
  props: ["item"],
  components: {
    VueQrcode,
    ModalQr,
  },
  data() {
    return {
      LikeT: false,
      ViewT: true,
      havepic: true,
      output: null,
      fileimg: null,
      showmodal: false,
    };
  },

  methods: {
    reloamodal() {
      $(`#${this.item.id}`).modal("show");
    },
    callemit() {
      //  @click="getchartdevice(e), GetHistoryChartViews(e)"
      this.$emit("getchartdevice", this.item.id);
      this.$emit("GetHistoryChartViews", this.item.id);
    },
    async status_code() {
      try {
        let data = {};
        data.modeAction = 1;
        data.mainDomainId = this.item.id;
        //  console.log(this.item);
        if (this.item.status_code == 0) {
          data.stt = 1;
        } else if (this.item.status_code == 1) {
          data.stt = 0;
        }
        //console.log(data);
        let getAPI = await this.$API.call_API(
          "post",
          "shorturl/UpdateActionListUrls",
          data,
          "auth"
        );
        this.$emit("calldata");
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async is_favourite() {
      try {
        let data = {};
        data.modeAction = 0;
        data.mainDomainId = this.item.id;
        //  console.log(this.item);
        if (this.item.is_favourite == 0) {
          data.stt = 1;
        } else if (this.item.is_favourite == 1) {
          data.stt = 0;
        }
        //console.log(data);
        let getAPI = await this.$API.call_API(
          "post",
          "shorturl/UpdateActionListUrls",
          data,
          "auth"
        );
        this.$emit("calldata");
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },

    copyURL() {
      this.$copyText(this.item.short_url).then(
        function (e) {
          console.log("Copied");
          console.log(e);
        },
        function (e) {
          console.log("Can not copy");
          console.log(e);
        }
      );
    },
    async qrsave() {
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
      };
      this.output = await this.$html2canvas(el, options);

      //console.log(this.output);

      var link = document.createElement("a");
      link.href = this.output; //  link.href = uri;
      link.download = `EcoTechQR_${this.item.alias}.png`; // link.download = filename;
      document.body.appendChild(link);
      link.click();
    },

    // async qrsave() {
    //   const el = this.$refs.printMe;
    //   // add option type to get the image version
    //   // if not provided the promise will return
    //   // the canvas.
    //   const options = {
    //     type: "dataURL",
    //   };
    //   this.output = await this.$html2canvas(el, options);
    //   this.fileimg = this.dataURLtoFile(this.output, "QRName");
    //   let data = { file: this.fileimg };
    //   console.log(this.output);
    //   // let getAPI = await serviceAPI.call_API('post','shortUrl/dataURLtoFile',data,'auth');
    // },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scope>
.maincard {
  background: linear-gradient(80deg, #64748b 4.88%, #9eadc4 126.38%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  z-index: 10;
}
.sidecard {
  background: linear-gradient(82.54deg, #64748b 39.16%, #e6e6e6 99.89%);
  border-radius: 0px 21px 21px 0px;
  padding-left: 35px;
  margin-left: -20px;
  margin-right: -20px;
  z-index: 1;
}
.viewcard {
  background: #e2e8f0;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: black;
}
.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.1rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 20%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  padding: 0px;
}
.myborder {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
}
</style>