<template>
  <div
    class="modal fade"
    id="MadalAdd"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- ---------------- body -------------------- -->
        <div class="modal-body m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-0 d-flex justify-content-end">
              <button
                type="button"
                class="close mr-4 mt-2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <!-- ---------------------------- -->
          <div class="row mx-0 mt-5 p-0">
            <div class="col-6 m-0 p-0">
              <div class="mainhead col-10 m-0 p-0 py-2">
                <p class="tital pl-3">{{ date }}{{ datadate }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- ---------------------------- -->
        <div class="row mx-0 mt-5 p-0">
          <div class="col-6 m-0 p-0 d-flex justify-content-center">
            <button
              :class="`textbutton  typedomain ${
                typeDomain[0].status ? 'activedomain' : 'unactivedomain'
              }`"
              @click="picktype(0)"
            >
              DEFAULT DOMAIN
            </button>
          </div>
          <!-- ------------------------- -->
          <div class="col-6 m-0 p-0 d-flex justify-content-center">
            <button
              :disabled="CUSTOM"
              :class="`textbutton  typedomain ${
                typeDomain[1].status ? 'activedomain' : 'unactivedomain'
              }`"
              @click="picktype(1)"
            >
              CUSTOM DOMAIN
            </button>
          </div>
        </div>
        <!-- ---------------------------- -->

        <div class="row text-left mx-5 my-3">
          <div class="col">
            <div class="row h-100 d-flex align-content-between flex-wrap">
              <div
                class="col-12 p-0 text-center mt-5"
                style="height: 70% !important"
              >
                <vue-qrcode
                  class="qrcodedomain"
                  value="http://localhost:8082/ShortUrl"
                ></vue-qrcode>
              </div>
              <div class="col-12" v-if="qrcodestatus">
                <label for="qrcodeurl-txt" class="fontdomain">QRCode Url</label>
                <input
                  type="text"
                  name="qrcodeurl-txt"
                  id="qrcodeurl-txt"
                  class="inputdomain form-control"
                  placeholder="Input text here"
                  autocomplete="off"
                  v-model="qrImageUrl"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12">
                <div v-if="typeDomain[1].status">
                  <label for="cusdomain-txt" class="fontdomain"
                    >choose domain (Ex: http://example.com/)</label
                  >
                  <input
                    type="text"
                    name="cusdomain-txt"
                    id="cusdomain-txt"
                    class="inputdomain form-control"
                    placeholder="Input text here"
                    autocomplete="off"
                    v-model="customDomain"
                    @blur="domainOut()"
                  />
                </div>
              </div>
              <div class="col-12">
                <label for="alias-txt" class="fontdomain">Alias</label>
                <input
                  type="text"
                  name="alias-txt"
                  id="alias-txt"
                  class="inputdomain form-control"
                  placeholder="Input text here"
                  autocomplete="off"
                  v-model="alias"
                />
              </div>
              <div class="col-12">
                <label for="originurl-txt" class="fontdomain"
                  >origin Url (Ex: http://example.com/)</label
                >
                <input
                  type="text"
                  name="originurl-txt"
                  id="originurl-txt"
                  class="inputdomain form-control"
                  placeholder="Input text here"
                  autocomplete="off"
                  v-model="originalUrl"
                  @blur="originOut()"
                />
              </div>
              <div class="col-12">
                <label for="path-txt" class="fontdomain">path</label>
                <input
                  type="text"
                  name="path-txt"
                  id="path-txt"
                  :class="`inputdomain form-control ${
                    pathstatus ? '' : 'inputdis'
                  }`"
                  placeholder="Input text here"
                  autocomplete="off"
                  v-model="customerPath"
                  :disabled="!pathstatus"
                />
                <div class="checkbox-theme-default custom-checkbox text-right">
                  <input
                    class="checkbox"
                    type="checkbox"
                    id="check-1"
                    @click="pathstatus = !pathstatus"
                  />
                  <label for="check-1">
                    <span
                      class="checkbox-text"
                      style="
                        width: 93px;
                        height: 20px;
                        font-family: Inter;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 20px;
                        color: #0f172a;
                      "
                    >
                      custom path
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ---------------------------- -->
        <div class="row mx-5 mb-2">
          <div class="col-12 text-left">
            <label for="originurl-txt" class="fontdomain text-left"
              >remark</label
            >
            <input
              type="text"
              name="originurl-txt"
              id="originurl-txt"
              class="inputdomain w-80 form-control"
              placeholder="Input text here"
              autocomplete="off"
              v-model="remark"
            />
          </div>
        </div>
        <!-- ---------------------------- -->
        <div class="row my-3 d-flex justify-content-center">
          <button
            class="col-3 typedomain activedomain"
            @click="AddShortenerURL()"
          >
            Save
          </button>
        </div>
        <!-- ---------------- body -------------------- -->
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  name: "MadalAdd",

  components: { VueQrcode },
  data() {
    return {
      date: this.$DF(new Date(), "dd mmm yyyy"),
      originalUrl: "",
      CUSTOM: true,
      customDomain: "",
      alias: "",
      remark: "",
      customerPath: "",
      qrImageUrl: "",
      qrcodestatus: false,
      pathstatus: false,
      typeDomain: [
        {
          type: "DEFAULT DOMAIN",
          status: true,
        },
        {
          type: "CUSTOM DOMAIN",
          status: false,
        },
      ],
    };
  },
  methods: {
    async openModal() {
      try {
        this.$refs.CheckStockRef.showmodal();
        this.onModal();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async hideModal() {
      try {
        // this.$refs.mymodal.hidemodal();
        $("#MadalAdd").modal("hide");
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async onModal() {
      try {
        //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    picktype(index) {
      for (let i = 0; i < this.typeDomain.length; i++) {
        if (i == index) {
          this.typeDomain[i].status = true;
        } else {
          this.typeDomain[i].status = false;
        }
      }
    },
    domainOut() {
      let url = this.customDomain;
      if (url.substring(0, 5) == "https") {
        this.customDomain = url.substring(0, 4) + url.substring(5, url.length);
      } else if (url.substring(0, 4) != "http") {
        this.customDomain = "http://" + url;
      }
      if (url.substring(url.length - 1, url.length) != "/") {
        this.customDomain = this.customDomain + "/";
      }
    },
    originOut() {
      let url = this.originalUrl;
      if (url.substring(0, 5) == "https") {
        this.originalUrl = url.substring(0, 4) + url.substring(5, url.length);
      } else if (url.substring(0, 4) != "http") {
        this.originalUrl = "http://" + url;
      }
      if (url.substring(url.length - 1, url.length) != "/") {
        this.originalUrl = this.originalUrl + "/";
      }
    },
    async AddShortenerURL() {
      try {
        if (this.originalUrl == "" || this.alias == "") {
          this.hideModal();
          throw "Please enter Alias and origin Url";
        }
        let data = {
          jsnDomainData: this.getJsnDomainData,
        };
        let getAPI = await this.$API.call_API(
          "post",
          "shorturl/AddShortenerURL",
          data,
          "auth"
        );
        // console.log("AddJobStageTemplates:")
        // console.log(getAPI);
        // $('#new-member').modal('hide');
        // this.$emit('addTemplate')
        // this.refreshForm()
        this.$MAIN.showSuccessAlert(this, "Completed");
        this.hideModal();
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
  },
  computed: {
    getJsnDomainData: function () {
      return {
        originalUrl: this.originalUrl,
        customDomain: this.customDomain || "https://short.ecotech.co.th/",
        alias: this.alias,
        remark: this.remark,
        customerPath: this.customerPath,
        qrImageUrl: this.qrImageUrl,
      };
    },
  },
};
</script>

<style scoped>
.mainhead {
  background: #475569;
  border-radius: 0px 48px 48px 0px;
  color: white;
}
.typedomain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;

  width: 308px;
  height: 43.56px;
  color: white;
}
.activedomain {
  background: #475569;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.unactivedomain {
  background: #94a3b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.fontdomain {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #475569;
}
.inputdomain {
  height: 52px;
  width: 100%;
  /* Shades/White */

  background: #ffffff;
  /* Neutral / Gray 300 */

  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  border-radius: 4px;
}
.qrcodedomain {
  border: 1px solid #000000;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 14px;
}
.inputdis {
  background-color: #cbd5e1 !important;
}
.tital {
  font-weight: 600;
  font-size: 2.25em;
  color: #ffffff;
}
.textbutton {
  color: #ffffff;
  font-weight: 700;
  font-size: 0.875em;
}
</style>