<template>
  <section>
    <!-- <button @click="openModal" type="button" class="btn-twd-nocolor bg-black font-kitti-medium my-1`">
            
        </button> -->
    <button
      @click="openModal"
      type="button"
      class="
        col-12
        p-3
        text-white
        bg-gray
        border border-gray
        rounded-pill
        p-0
        text-center
      "
    >
      <slot>Add</slot>
    </button>
    <Modal ref="CheckStockRef" size="80">
      <template v-slot:header> </template>
      <template v-slot:body>
        <div class="row m-0">
          <div class="mainhead col-5 p-0">
            <div class="row">
              <div class="col-12 text-center" style="font-size: 2.5rem">
                Your domain
              </div>
              <div class="col-12 text-center">milada</div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-12 mb-2">
            <div class="row my-3">
              <div class="col"></div>
              <div
                :class="`col typedomain ${
                  typeDomain[0].status ? 'activedomain' : 'unactivedomain'
                }`"
                @click="picktype(0)"
              >
                DEFAULT DOMAIN
              </div>
              <div class="col"></div>
              <div
                :class="`col typedomain ${
                  typeDomain[1].status ? 'activedomain' : 'unactivedomain'
                }`"
                @click="picktype(1)"
              >
                CUSTOM DOMAIN
              </div>
              <div class="col"></div>
            </div>
            <div class="row text-left mx-5 my-3">
              <div class="col">
                <div class="row h-100 d-flex align-content-between flex-wrap">
                  <div
                    class="col-12 p-0 text-center"
                    style="height: 70% !important"
                  >
                    <vue-qrcode
                      class="w-auto h-100 qrcodedomain"
                      value="http://localhost:8082/ShortUrl"
                    ></vue-qrcode>
                  </div>
                  <div class="col-12" v-if="qrcodestatus">
                    <label for="qrcodeurl-txt" class="fontdomain"
                      >QRCode Url</label
                    >
                    <input
                      type="text"
                      name="qrcodeurl-txt"
                      id="qrcodeurl-txt"
                      class="inputdomain form-control"
                      placeholder="Input text here"
                      autocomplete="off"
                      v-model="qrImageUrl"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-12">
                    <div v-if="typeDomain[1].status">
                      <label for="cusdomain-txt" class="fontdomain"
                        >choose domain (Ex: http://example.com/)</label
                      >
                      <input
                        type="text"
                        name="cusdomain-txt"
                        id="cusdomain-txt"
                        class="inputdomain form-control"
                        placeholder="Input text here"
                        autocomplete="off"
                        v-model="customDomain"
                        @blur="domainOut()"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <label for="alias-txt" class="fontdomain">Alias</label>
                    <input
                      type="text"
                      name="alias-txt"
                      id="alias-txt"
                      class="inputdomain form-control"
                      placeholder="Input text here"
                      autocomplete="off"
                      v-model="alias"
                    />
                  </div>
                  <div class="col-12">
                    <label for="originurl-txt" class="fontdomain"
                      >origin Url (Ex: http://example.com/)</label
                    >
                    <input
                      type="text"
                      name="originurl-txt"
                      id="originurl-txt"
                      class="inputdomain form-control"
                      placeholder="Input text here"
                      autocomplete="off"
                      v-model="originalUrl"
                      @blur="originOut()"
                    />
                  </div>
                  <div class="col-12">
                    <label for="path-txt" class="fontdomain">path</label>
                    <input
                      type="text"
                      name="path-txt"
                      id="path-txt"
                      :class="`inputdomain form-control ${
                        pathstatus ? '' : 'inputdis'
                      }`"
                      placeholder="Input text here"
                      autocomplete="off"
                      v-model="customerPath"
                      :disabled="!pathstatus"
                    />
                    <div
                      class="checkbox-theme-default custom-checkbox text-right"
                    >
                      <input
                        class="checkbox"
                        type="checkbox"
                        id="check-1"
                        @click="pathstatus = !pathstatus"
                      />
                      <label for="check-1">
                        <span
                          class="checkbox-text"
                          style="
                            width: 93px;
                            height: 20px;
                            font-family: Inter;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 20px;
                            color: #0f172a;
                          "
                        >
                          custom path
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-5 mb-2">
              <div class="col-12 text-left">
                <label for="originurl-txt" class="fontdomain text-left"
                  >remark</label
                >
                <input
                  type="text"
                  name="originurl-txt"
                  id="originurl-txt"
                  class="inputdomain w-80 form-control"
                  placeholder="Input text here"
                  autocomplete="off"
                  v-model="remark"
                />
              </div>
            </div>
            <div class="row my-3 d-flex justify-content-center">
              <div
                class="col-3 typedomain activedomain"
                @click="AddShortenerURL()"
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import Modal from "@/components/shorturl/Modal.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  name: "ShortUrlAdd",
  components: {
    Modal,
    VueQrcode,
  },
  data() {
    return {
      originalUrl: "",
      customDomain: "",
      alias: "",
      remark: "",
      customerPath: "",
      qrImageUrl: "",
      qrcodestatus: false,
      pathstatus: false,
      typeDomain: [
        {
          type: "DEFAULT DOMAIN",
          status: true,
        },
        {
          type: "CUSTOM DOMAIN",
          status: false,
        },
      ],
    };
  },
  methods: {
    async openModal() {
      try {
        this.$refs.CheckStockRef.showmodal();
        this.onModal();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async hideModal() {
      try {
        this.$refs.CheckStockRef.hidemodal();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async onModal() {
      try {
        //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    picktype(index) {
      for (let i = 0; i < this.typeDomain.length; i++) {
        if (i == index) {
          this.typeDomain[i].status = true;
        } else {
          this.typeDomain[i].status = false;
        }
      }
    },
    domainOut() {
      let url = this.customDomain;
      if (url.substring(0, 5) == "https") {
        this.customDomain = url.substring(0, 4) + url.substring(5, url.length);
      } else if (url.substring(0, 4) != "http") {
        this.customDomain = "http://" + url;
      }
      if (url.substring(url.length - 1, url.length) != "/") {
        this.customDomain = this.customDomain + "/";
      }
    },
    originOut() {
      let url = this.originalUrl;
      if (url.substring(0, 5) == "https") {
        this.originalUrl = url.substring(0, 4) + url.substring(5, url.length);
      } else if (url.substring(0, 4) != "http") {
        this.originalUrl = "http://" + url;
      }
      if (url.substring(url.length - 1, url.length) != "/") {
        this.originalUrl = this.originalUrl + "/";
      }
    },
    async AddShortenerURL() {
      try {
        let data = {
          jsnDomainData: this.getJsnDomainData,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "shorturl/AddShortenerURL",
          data,
          "auth"
        );
        // console.log("AddJobStageTemplates:")
        // console.log(getAPI);
        // $('#new-member').modal('hide');
        // this.$emit('addTemplate')
        // this.refreshForm()
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
  },
  computed: {
    getJsnDomainData: function () {
      return {
        originalUrl: this.originalUrl,
        customDomain: this.customDomain || "https://short.ecotech.co.th/",
        alias: this.alias,
        remark: this.remark,
        customerPath: this.customerPath,
        qrImageUrl: this.qrImageUrl,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.mainhead {
  background: #475569;
  border-radius: 0px 48px 48px 0px;
  color: white;
}
.typedomain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;

  width: 308px;
  height: 43.56px;
  color: white;
}
.activedomain {
  background: #475569;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.unactivedomain {
  background: #94a3b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.fontdomain {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #475569;
}
.inputdomain {
  height: 52px;
  width: 100%;
  /* Shades/White */

  background: #ffffff;
  /* Neutral / Gray 300 */

  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  border-radius: 4px;
}
.qrcodedomain {
  border: 1px solid #000000;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 14px;
}
.inputdis {
  background-color: #cbd5e1 !important;
}
</style>