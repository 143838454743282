<template>
  <div class="m-0 px-2 py-5">
    <div class="row p-0" v-for="(e, index) in calvalue" :key="index">
      <div class="col-8 m-0 p-0">
        <div
          class="m-0 p-0"
          :style="`background-color:${e.bg};
        width: ${e.w}%; height:100%;`"
        >
          <p class="pl-2 lable">{{ e.device }}</p>
        </div>
      </div>
      <div class="col value text-center">{{ e.count }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyChart",
  props: ["data"],
  data() {
    return {
      calvalue: [],
    };
  },
  mounted() {
    this.cal();
  },
  methods: {
    cal() {
      let max = 0;
      let data = this.data;
      //console.log(data);
      for (var i in data) {
        if (Number(i) % 2 == 0) {
          this.data[i].bg = "#F0FFFF";
        } else {
          this.data[i].bg = "#F0F8FF";
        }

        if (max < data[i].count) max = data[i].count;
      }
     // console.log(max);
      for (var i in data) {
        data[i].w = (Number(data[i].count) * 100) / Number(max);
        //console.log(data);
      }
      this.calvalue = data;
    },
  },
};
</script>

<style scoped>
.value {
  color: #000000;
  font-weight: 400;
  font-size: 0.75em;
}
.lable {
  position: absolute;
  color: #263238;
  font-weight: 400;
  font-size: 0.75em;
}
</style>