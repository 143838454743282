<template>
  <!-- Modal -->
  <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row m-0 p-0">
            <div
              ref="printMe"
              class="col-12 m-0 p-0 d-flex justify-content-center"
            >
              <figure type="button" class="mt-2 ml-2 qrcode myborder">
                <VueQrcode
                  class="w-100"
                  :value="mydata"
                  tag="svg"
                  :options="{
                    errorCorrectionLevel: 'Q',
                  }"
                ></VueQrcode>
                <img class="qrcode__image" src="/images/logo_eco.png" />
              </figure>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Understood</button>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  nam: "ModalQr",
  props: ["mydatamodal", "modalId"],
  data() {
    return {
      mydata: this.mydatamodal,
    };
  },
  components: {
    VueQrcode,
  },
  mounted() {
  },
};
</script>

<style>
</style>