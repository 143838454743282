<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">
            
            <div class="container-fluid">
                <ShortUrl/>
                <!-- <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-12">
                            <BreadCrumbDate defaultType="MONTH" namePage="Short Url" :hideToday="true" :hideYear="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">  
                          
                        </div>
                      
                    </div>
                </div> -->
            </div>

        </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from '@/components/main/BreadCrumbDate.vue';
import ShortUrl from '@/components/shorturl/ShortUrl.vue';

export default {
  name: 'ShortUrlPage_BK_TEE',
  components: {
    BreadCrumbDate,
    ShortUrl,

  },
  data: function () {
    return {
        
      }
    },
  mounted() {
    this.$store.dispatch('display/SET_pageName',"ShortUrl Page")
  },
  methods: {
 
  }
}

</script>