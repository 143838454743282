<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card mx-0 px-0 pb-5">
      <!-- -------------------------- Header--------------------- -->
      <div class="row m-0 p-0 w-100">
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-4 m-0 p-0">
          <LeftHeader tital="Your Domain" subtital="Milada" line="1.2" />
        </div>
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-8 m-0">
          <CardDate @get_date="get_date" />
        </div>
      </div>
      <!-- ------------------------------- -->
      <div class="row mx-0 mt-4 p-0">
        <div class="col-12 col-md-10 mt-1 mx-0 pl-5 text-right">
          <div class="m-0 pl-5">
            <RightHeaderSearch @common_btn_search="common_btn_search" />
          </div>
        </div>
        <div class="col-12 col-md m-0 px-2">
          <Button1  tital="Add" data-toggle="modal" data-target="#MadalAdd" />
        </div>
      </div>
      <!-- ------------------------------------------- -->
      <div class="row mx-0 mt-4 p-0">
        <div class="col-12 col-md-8 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-12 mx-0 mb-3 pl-5" v-if="itemdevice.length > 0">
              <ShortUrlCardDetail :itemdevice="itemdevice" />
            </div>
            <div class="col-12 mx-0 mb-3 p-0 w-100" v-if="Histogram.length > 0">
              <ShortUrlChart
                :key="reload"
                :Histogram="Histogram"
                :label="label"
                :max="max"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md m-0 p-0">
          <div class="mb-3 p-0 " v-for="(e, i) in dataShotURL" :key="i">
            <div class="m-0 p-0" :class="id==e.id?'myborder':''">
              <ShortUrlCard
                
                @getchartdevice='getchartdevice'
                @GetHistoryChartViews='GetHistoryChartViews'
                type="button"
                @calldata="calldata"
                :item="e"
                class=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------- model ------------------------- -->
    <MadalAdd />
    <!-- ---------------------------- modal --------------------------------- -->
  </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from "@/components/main/BreadCrumbDate.vue";
import ShortUrl from "@/components/shorturl/ShortUrl.vue";
import LeftHeader from "@/components/Common/LeftHeader.vue";
import RightHeaderSearch from "@/components/Common/RightHeaderSearch.vue";
import Button1 from "@/components/Common/Button1.vue";
import CardDate from "@/components/shorturl/CardDate.vue";
import ShortUrlCardDetail from "@/components/shorturl/ShortUrlCardDetail.vue";
import ShortUrlChart from "@/components/shorturl/ShortUrlChart.vue";
import ShortUrlCard from "@/components/shorturl/ShortUrlCard.vue";
import MadalAdd from "@/components/shorturl/MadalAdd.vue";
import { Calendar, DatePicker } from "v-calendar";
export default {
  name: "ShortUrlPage",
  components: {
    BreadCrumbDate,
    ShortUrl,
    LeftHeader,
    RightHeaderSearch,
    CardDate,
    Button1,
    ShortUrlCardDetail,
    ShortUrlCard,
    ShortUrlChart,
    MadalAdd,
  },
  data() {
    return {
      id: "",
      data: { dt: "", filter: "", txtSearch: "" },
      dataShotURL: "",
      itemdevice: [],
      Histogram: [],
      label: [],
      max: "",
      reload: "",
    };
  },
  mounted() {},
  methods: {
    async getchartdevice(value) {
      //console.log('GetChartDevices');
      // call SHORTENERURL.GetChartDevices(`mainDomainId`,`dt`,`filters`)
      try {
        //  console.log(value);
        let data = {
          mainDomainId: value ,
          dt: this.data.dt,
          filters: this.data.filter,
        };
        this.id =  value;
        //console.log(data);
        let getAPI = await this.$API.call_API(
          "post",
          "shorturl/GetChartDevices",
          data,
          "auth"
        );
        // console.log(getAPI.data.data);
        this.itemdevice = getAPI.data.data;

        // this.Histogram = JSON.parse(getAPI.data.data[0].data);
        // // console.log(this.Histogram);
        // this.reload = Date.now();
        // this.GetChartDevices(value);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async GetHistoryChartViews(value) {
      try {
        // console.log(value);
        let data = { mainDomainId: value , dt: this.data.dt };
        let getAPI = await this.$API.call_API(
          "post",
          "shorturl/GetHistoryChartViews",
          data,
          "auth"
        );
        // console.log(getAPI.data.data[0]);
        this.Histogram = JSON.parse(getAPI.data.data[0].data);
        this.label = JSON.parse(getAPI.data.data[0].label);
        this.max = getAPI.data.data[0].max;

        // console.log(this.Histogram);
        this.reload = Date.now();
        this.GetChartDevices(value);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async GetChartDevices(value) {},
    get_date(value) {
      // console.log(value);
      this.data.dt = value;

      this.calldata();
    },
    common_btn_search(value) {
      // console.log(value);
      this.data.filter = value.btn;
      this.data.txtSearch = value.input;

      this.calldata();
    },
    async calldata() {
      try {
        let data = this.data;
        this.Histogram = [];
        this.itemdevice=[]
        this.reload = Date.now();
        let getAPI = "";
        if (data.filter !== "" && data.dt !== "") {
          //console.log(data);
          getAPI = await this.$API.call_API(
            "post",
            "shorturl/GetShortUrlByUser",
            data,
            "auth"
          );
          //console.log(getAPI.data.data);
          this.dataShotURL = getAPI.data.data;
        }

        //console.log(`getAPI : ${getAPI}` );
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
  },
};
</script>

<style scoped>
.myborder {
  border-radius: 19px;
  border: 0px solid #ffffff;
  box-shadow: 0px 20px 56px rgba(0, 0, 0, 0.29);
 
}
</style>

